const systemName = process.env.VUE_APP_SYSTEM_NAME;
const faviconObj = {
  xinzhou: {
    url: '/favicon-xinzhou.ico',
    title: '新舟教学教研系统'
  },
  jinbang: {
    url: '/favicon-jinbang.ico',
    title: '金榜爱学'
  },
  xinbo: {
    url: '/favicon-xinbo.ico',
    title: '馨博教育'
  },
  shangzhi: {
    url: '/favicon-shangzhi.ico',
    title: '上志教育'
  }
};
(function (systemName) {
  document.querySelector('link[rel="icon"]')!.setAttribute('href', faviconObj[systemName] ? faviconObj[systemName].url : '/favicon.ico');
  document.title = faviconObj[systemName] ? faviconObj[systemName].title : '爱学';
}(systemName));

