/**
 * @module Axios 配置信息
 */

import axios, { AxiosRequestConfig } from 'axios';
import VueAxios from 'vue-axios';
import Qs from 'qs';
import { Message } from 'element-ui';
import router from '@/router';

/* ------------------------- 默认请求格式, 和全局请求地址 ------------------------- */
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = 'http://xiaohe.vaiwan.com/';

axios.interceptors.request.use((res: AxiosRequestConfig) => {
  /* ------------ 序列化参数 ------------- */
  localStorage.getItem('accessToken') ? res.headers.accessToken = localStorage.getItem('accessToken') : '';
  localStorage.getItem('userInfo')? res.headers.userId =  JSON.parse(localStorage.getItem('userInfo') as string).id : '';
  res.headers.system = 2019;
  res.headers.os = 2019;
  res.data = res.data || {};
  if (res.headers['Content-Type'] === 'application/json') {
    return res;
  }
  res.data = Qs.stringify(res.data);
  return res;
});

/* ----------------- 返回结果拦截, 如未登录直接跳转到登录页 ----------------- */
axios.interceptors.response.use(res => {
  if (res.data.code && res.data.code === 401) {
    router.push('/login');
    Message({ type: 'warning', message: res.data.message });
  }
  return res.data;
}, err => {
  if (err.response.status === 403) {
    router.push('/login');
    Message({ type: 'warning', message: '登录失效，请重新登录' });
  } else {
    Message({ type: 'error', message: '服务器开小差了，请稍后再试~！' });
  }
  return err;
});


export default {
  install: (vue) => { vue.use(VueAxios, axios) }
}
