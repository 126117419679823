import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/common.less'
import './setHead'

import Storage from './utils/storage';
Vue.use(Storage)

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
Vue.use(ElementUI, { size: Storage.get('size') || 'default'});

import Axios from './core/axios';
Vue.use(Axios);


import EventBus from './utils/event-bus';
Vue.use(EventBus)

import Drawer from './utils/drawer';
Vue.use(Drawer);
import Modal from './utils/modal';
Vue.use(Modal);


import Components from './components';
Vue.use(Components);

import Direactives from './utils/direactives';
Vue.use(Direactives);

import cusclickoutside from './utils/direactives/clickoutside';
Vue.directive('cusclickoutside', cusclickoutside)
Vue.config.productionTip = false;

window.console.log = process.env.NODE_ENV === 'development' ? window.console.log : () => {}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
