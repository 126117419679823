import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'hash',
  routes
});
const originalPush = VueRouter.prototype.push;
(VueRouter as any).prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  // @ts-ignore
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach((to, from) => {
  NProgress.done();
});


export default router;
