import Vue, { Component } from 'vue';
import ModalForm from './form';
import './modal.scss';

const create = (opt) => {
  let options = {
    title: opt.title || null,
    width: opt.width || 720,
    component: opt.component === 'form' ? ModalForm : opt.component,
    props: opt.props || {},
    zIndex: opt.zIndex || 200,
    mask: typeof opt.mask === 'undefined' ? true : opt.mask,
    maskClosable: typeof opt.maskClosable === 'undefined' ? true : opt.maskClosable,
    closable: typeof opt.closable === 'undefined' ? true : opt.closable,
    footed: typeof opt.footed === 'undefined' ? true : opt.footed,
    headerStyle: opt.headerStyle || {},
    bodyStyle: opt.bodyStyle || {},
  }

  const vm = new Vue({
    // render 生成虚拟dom
    render: h => h(options.component, {
      props: {
        ...options.props
      }
    })
  }).$mount();

  let modalRef: Vue = vm.$children[0];

  const carrier = document.createElement('div');
  let maskEl: HTMLElement = document.createElement('div');
  maskEl.className = 'modal-mask';
  maskEl.style.zIndex = `${ options.zIndex }`;
  if (options.mask) {
    carrier.appendChild(maskEl);
    if (options.maskClosable) {
      maskEl.onclick = () => {
        remove();
      }
    }
  }


  // 回收组件
  let remove = (val?) => {
    if (carrier && vm.$children.length) {
      maskEl.className = 'modal-mask active';
      modalBox.className = 'modal-box active';
      setTimeout(() => {
        document.body.removeChild(carrier);
      }, 500);
      vm.$children[0].$destroy(); // 销毁组件
    }
  };

  let modalBox = document.createElement('div');
  modalBox.className = 'modal-box';
  modalBox.style.width = options.width + 'px';
  modalBox.style.zIndex = `${options.zIndex + 1}`;

  let modalBody = document.createElement('div');
  /* ------- 设置 body 样式 ------- */
  Object.entries(options.bodyStyle).map(s => modalBody.style[s[0]] = s[1] );
  modalBody.className = 'modal-body';
  modalBody.appendChild(vm.$el);
  modalBox.appendChild(modalBody);
  if (options.title) {
    let ModalHeader = document.createElement('div');
    /* ------- 设置 header 样式 ------- */
    Object.entries(options.headerStyle).map(s => ModalHeader.style[s[0]] = s[1]);
    ModalHeader.className = 'modal-header';
    ModalHeader.innerHTML = `<span>${options.title}</span>`;
    if (options.closable) {
      let iconEl = document.createElement('i');
      iconEl.className = 'el-dialog__close el-icon el-icon-close';
      iconEl.onclick = () => { remove() };
      ModalHeader.appendChild(iconEl);
    }
    modalBox.insertBefore(ModalHeader, modalBox.children[0]);
  }
  if (options.footed) {
    let modalFooter = document.createElement('div');
    modalFooter.className = 'modal-footer';
    let closeBtn = document.createElement('button');
    let saveBtn = document.createElement('button');
    closeBtn.className = 'modal-close-btn';
    saveBtn.className = 'modal-save-btn';
    closeBtn.innerHTML = '<span>取消</span>';
    saveBtn.innerHTML = '<span>确定</span>';
    modalRef.$on('loading', (e: boolean) => {
      if (e) {
        saveBtn.innerHTML = `<i class="el-icon-loading"></i><span>加载中</span>`;
        saveBtn.className = 'modal-save-btn loading';
      } else {
        saveBtn.innerHTML = `<span>确定</span>`;
        saveBtn.className = 'modal-save-btn';
      }
    });
    closeBtn.onclick = () => { remove(); }
    saveBtn.onclick = () => {
      if (modalRef['save'] && modalRef['save'].constructor === Function) {
        modalRef['save']();
      } else {
        remove();
        console.warn(`请在 ${options.component.name} Component 中定义 save 方法`);
      }
    }
    modalFooter.appendChild(closeBtn)
    modalFooter.appendChild(saveBtn)
    modalBox.appendChild(modalFooter);
  }

  carrier.appendChild(modalBox);
  document.body.appendChild(carrier);


  modalRef.$on('close', remove)

  return modalRef;

}




export default { install: (vue: any) => (vue.prototype.$modal = { create }) };

declare module 'vue/types/vue' {
  interface Vue {
    $modal: { create: (options: ModalCreate) => Vue }
  }
}

interface ModalCreate {
  title?: string;
  width?: number;
  component: Component | 'form';     // 子组件
  mask?: boolean;          // 是否展示遮罩
  zIndex?: number;
  maskClosable?: boolean;  // 点击蒙层是否允许关闭
  closable?: boolean;      // 是否展示右上角关闭按钮
  props?: object;          // 传入子组件的值
  footed?: boolean;         // 是否拥有默认页脚
  headerStyle?: object;     // modal-header 样式
  bodyStyle?: object        // modal-body 样式
}
