import { RouteConfig } from 'vue-router';
import Cookie from 'js-cookie';

const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/index',
    component: () => import('@/layout/base.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        meta: { title: '首页' },
        component: () => import('@/views/index/index.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('accessToken') && localStorage.getItem('userInfo')) {
            next();
          } else {
            next({ name: 'login' });
          }
        },
      },

      {
        path: '/education',
        name: 'education',
        component: { render: h => h("router-view") },
        meta: { title: '教学基础管理' },
        children: [
          {
            path: 'year',
            name: 'year',
            component: () => import('@/views/education/year.vue'),
            meta: {
              type: 'YEAR',
              title: '年份管理'
            }
          },
          {
            path: 'subject',
            name: 'subject',
            component: () => import('@/views/education/subject.vue'),
            meta: {
              type: 'SUBJECT',
              title: '学科管理'
            }
          },
          {
            path: 'class-type',
            name: 'classType',
            component: () => import('@/views/education/classType.vue'),
            meta: {
              type: 'COURSE_TYPE',
              title: '班型管理'
            }
          },
          {
            path: 'grade',
            name: 'grade',
            component: () => import('@/views/education/grade.vue'),
            meta: {
              type: 'GRADE',
              title: '年级管理'
            }
          },
          {
            path: 'semester',
            name: 'semester',
            component: () => import('@/views/education/semester.vue'),
            meta: {
              type: 'SEMESTER',
              title: '学期管理'
            }
          },
          {
            path: 'version',
            name: 'version',
            component: () => import('@/views/education/version.vue'),
            meta: {
              type: 'TEACH_BOOK_VERSION',
              title: '教材版本管理'
            }
          },
          // {
          //   path: 'course-system',
          //   name: 'courseSystem',
          //   component: () => import('@/views/education/courseSystem.vue'),
          //   meta: {
          //     type: 'courseSystem',
          //     title: '课程体系'
          //   }
          // },
          // {
          //   path: 'chapter-system',
          //   name: 'chapterSystem',
          //   component: () => import('@/views/education/chapterSystem.vue'),
          //   meta: {
          //     type: 'chapterSystem',
          //     title: '章节体系'
          //   }
          // },
          // {
          //  path: 'knowledge',
          //  name: 'knowledge',
          //  component: () => import('@/views/education/knowledge.vue'),
          //   meta: {
          //    title: '知识点体系'
          //   }
          // },
          // {
          //   path: 'knowledge-element',
          //   name: 'knowledge-element',
          //   component: () => import('@/views/education/knowledge-element.vue'),
          //   meta: {
          //     title: '知识元体系'
          //   }
          // },
          {
            path: 'source',
            name: 'source',
            component: () => import('@/views/education/source.vue'),
            meta: {
              type: 'QUES_SOURCE',
              title: '来源管理'
            }
          },
          {
            path: 'category',
            name: 'category',
            component: () => import('@/views/education/category.vue'),
            meta: {
              type: 'QUES_CATEGORY',
              title: '题类管理'
            }
          },

          // {
          //   path: 'chapter-link-knowledge',
          //   name: 'chapterLinkKnowledge',
          //   component: () => import('@/views/education/chapterOrKnowledge.vue'),
          //   meta: {
          //     title: '章节知识点关联'
          //   }
          // }
        ]
      },

      {
        path: '/research',
        name: 'research',
        component: { render: h => h("router-view") },
        meta: { title: '教研体系' },
        children: [
          {
            path: 'course-system',
            name: 'courseSystem',
            component: () => import('@/views/education/courseSystem.vue'),
            meta: {
              type: 'courseSystem',
              title: '课程体系'
            }
          },
          {
            path: 'chapter-system',
            name: 'chapterSystem',
            component: () => import('@/views/education/chapterSystem.vue'),
            meta: {
              type: 'chapterSystem',
              title: '章节体系'
            }
          },
          {
           path: 'knowledge',
           name: 'knowledge',
           component: () => import('@/views/education/knowledge.vue'),
            meta: {
             title: '知识点体系'
            }
          },
          {
            path: 'knowledge-element',
            name: 'knowledge-element',
            component: () => import('@/views/education/knowledge-element.vue'),
            meta: {
              title: '知识元体系'
            }
          },
          {
            path: 'chapter-link-knowledge',
            name: 'chapterLinkKnowledge',
            component: () => import('@/views/education/chapterOrKnowledge.vue'),
            meta: {
              title: '章节知识点关联'
            }
          }
        ]
      },

      {
        path: '/customer',
        name: 'customer',
        component: { render: h => h("router-view") },
        meta: { title: '用户管理' },
        children: [
          {
            path: 'campus',
            name: 'campus',
            meta: { title: '校区管理' },
            component: () => import('@/views/customer/campus.vue')
          },
          {
            path: 'teacher',
            name: 'teacher',
            meta: { title: '教师管理' },
            component: () => import(/* webpackChunkName: "teacher" */'@/views/customer/teacher.vue')
          },
          {
            path: 'role',
            name: 'role',
            meta: { title: '角色管理' },
            component: () => import('@/views/customer/role.vue')
          },
            // {
            //     path: 'student',
            //     name: 'student',
            //     meta: { title: '学生管理' },
            //     component: () => import('@/views/customer/student/student.vue')
            // },
            // {
            //     path: 'classes',
            //     name: 'classes',
            //     meta: { title: '班级管理' },
            //     component: () => import('@/views/customer/classes/classes.vue')
            // }
        ]
      },
      {
        path: '/manage',
        name: 'manage',
        component: { render: h => h("router-view") },
        meta: { title: '教务管理' },
        children: [
            {
                path: 'student',
                name: 'student',
                meta: { title: '学生管理' },
                component: () => import(/* webpackChunkName: "student" */'@/views/customer/student/student.vue')
            },
            {
                path: 'classes',
                name: 'classes',
                meta: { title: '班级管理' },
                component: () => import('@/views/customer/classes/classes.vue')
            }
        ]
      },

      {
        path: '/mould',
        name: 'mould',
        component: {render: h => h('router-view')},
        meta: {title: '模板管理'},
        children: [
          {
            path: 'element-mould',
            name: 'element-mould',
            component: () => import('@/views/mould/element-mould.vue'),
            meta: {
              title : '知识元模板',
            },
            children: [
              {
                path: 'editModel',
                name: 'editModel',
                component: () => import('@/views/mould/editModel/index.vue'),
                meta: {
                  title : '编辑',
                }
              },
            ]
          },
          {
            path: 'element-moulds',
            name: 'element-moulds',
            component: {render: h => h('router-view')},
            meta: {
              title : '知识元模板',
            },
            children: [
              {
                path: 'editModel',
                name: 'editModel',
                component: () => import('@/views/mould/editModel/index.vue'),
                meta: {
                  title : '编辑',
                }
              },
            ]
          },
          {
            path: 'paper',
            name: 'paper',
            component: () => import('@/views/mould/paper.vue'),
            meta: {
              title : '试卷管理',
            }
          }
        ]
      },
      {
        path: '/system',
        name: 'system',
        component: { render: h => h("router-view") },
        meta: { title: '系统设置' },
        children: [
          {
            path: 'question-type',
            name: 'question-type',
            meta: { title: '题型设置' },
            component: () => import('@/views/system/question-type.vue')
          },
          {
            path: 'school',
            name: 'school',
            meta: { title: '公立校管理' },
            component: () => import('@/views/system/school.vue')
          },
          {
            path: 'course-setting',
            name: 'course-setting',
            meta: { title: '备课设置' },
            component: () => import('@/views/system/course-setting.vue')
          },
          {
            path: 'icon-manage',
            name: 'icon-manage',
            component: () => import('@/views/system/icon-manage.vue'),
            meta: {
              title: '图标管理'
            }
          },
        ]
      }
    ]
  },
  {
    path: '/error/:type',
    name: '404',
    component: () => import('@/views/error.vue')
  },
  // {
  //   path: '/test-paper-edit/:preview/:id',
  //   meta: { title: '编辑试卷' },
  //   component: () => import('@/views/mould/test-paper.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '*',
    redirect: '/error/404'
  }
]
export default routes;
