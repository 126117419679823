import { Component, Prop, Vue } from 'vue-property-decorator';
import { pca } from 'area-data';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'modal-form',
})
export default class ModalForm extends Vue {

  @Prop() nodes!: any[];

  @Prop({ default: () => new Object() }) rules!: any;

  @Prop({ default: () => new Object() }) data!: any;

  private formGroup: any = {};

  constructor(props) {
    super(props);
    this.nodes.map((node, idx) => {
      if (node.keys) {
        this.formGroup[node.keys[0]] = this.data[node.keys[0]] || null;
        this.formGroup[node.keys[1]] = this.data[node.keys[1]] || null;
      } else {
        this.formGroup[node.key] = this.data[node.key] || node.default;
      }
      if (node.region && node.region === 'province') {
        node.options = Object.entries(pca[86]).map(i => { return { id: i[0], name: i[1] } });
      }
      if (node.url) {
        this.axios.post(node.url).then((res: any) => {
          node.options = [...res.json, ...(node.options || [])];
          this.$set(this.nodes, idx, node)
          this.$forceUpdate()
        });
      }
    })
  }
  created() {
    this.$nextTick(() => {
      (this.$refs.ruleForm as ElForm).resetFields();
    })
  }

  private async save() {
    let valid = await (this.$refs.ruleForm as ElForm).validate();
    if (valid) {
      let data = { ...this.formGroup };
      Object.keys(data).map(key => (data[key] === undefined || data[key] === null || data[key] === '') && delete data[key]);
      this.$emit('close', data);
    }
  }

  /* 联动触发 */
  private linkage(v, node) {
    if (node.region && node.region === 'province') {
      this.nodes.map(n => {
        if (n.key === node.child) {
          n.options = v ? Object.entries(pca[v]).map(i => { return { id: i[0], name: i[1] } }) : [];
          this.formGroup[n.key] = null;
        }
        return n;
      })
    }
  }

  protected render() {
    return (
      <el-form props={{ model: this.formGroup, rules: this.rules }} ref="ruleForm" label-width="80px" class="cus__modal__form__container">

        <input type="text" style="display:none" />

        {this.nodes.map((node: any) => {
          return (
            <el-form-item label={ node.label } prop={ node.key }>
              {
                (() => {
                  switch (node.type) {
                    case 'input': {
                      let n = node as NInput;
                      return (
                        <el-input
                          clearable
                          value={this.formGroup[n.key]}
                          onInput={(v: string) => { this.formGroup[n.key] = v }}
                          placeholder={n.placeholder || `请输入${n.label}`}
                          disabled={ n.disabled }
                        />
                      )
                    }
                    case 'number': {
                      let n = (node as NNumber);
                      return (
                        <el-input-number
                          clearable
                          controls-position="right"
                          value={this.formGroup[n.key]}
                          onInput={(v: number) => { this.formGroup[n.key] = v }}
                          placeholder={n.placeholder || `请输入${n.label}`}
                          disabled={ n.disabled }
                        />
                      )
                    }
                    case 'select': {
                      let n = node as NSelect;
                      return (
                        <el-select
                          disabled={ n.disabled }
                          clearable
                          multiple={ node.multiple }
                          value={this.formGroup[n.key]}
                          onInput={(v) => {
                            this.formGroup[n.key] = v;
                            node.child && this.linkage(v, node)
                          }}
                          placeholder={n.placeholder || `请选择${n.label}`}>
                          {
                            n.options?.map(option => (
                              <el-option key={option[n.valueKey || 'id']} label={option[n.labelKey || 'name']} value={option[n.valueKey || 'id']} />
                            ))
                          }
                        </el-select>
                      )
                    }
                    case 'datepicker': {
                      let n = node as NDatepicker;
                      return (
                        <el-date-picker
                          disabled={ n.disabled }
                          type="date"
                          value-format={node.format || 'yyyy-MM-dd'}
                          value={this.formGroup[n.key]}
                          onInput={(v) => { this.formGroup[n.key] = v; }}
                          placeholder={n.placeholder || `请选择${n.label}`}
                        />
                      )
                    }
                    case 'rangepicker': {
                      let n = node as NRangepicker;
                      return (
                        <el-date-picker
                          disabled={ n.disabled }
                          type="daterange"
                          value-format={node.format || 'yyyy-MM-dd'}
                          range-separator="~"
                          value={this.formGroup[n.key]}
                          onInput={(v) => { this.formGroup[n.key] = v; }}
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        />
                      )
                    }
                    case 'between': {
                      let n = node as NBetween;
                      return (
                        <el-input readonly value="~" style="vertical-align: initial">
                          <el-input
                            clearable
                            slot="prepend"
                            style={{ width: '100px' }}
                            value={this.formGroup[n.keys[0]]}
                            onInput={(v: string) => { this.formGroup[n.keys[0]] = v }}
                            placeholder="开始"
                          />
                          <el-input
                            clearable
                            slot="append"
                            style={{ width: '100px' }}
                            value={this.formGroup[n.keys[1]]}
                            onInput={(v: string) => { this.formGroup[n.keys[1]] = v }}
                            placeholder="结束"
                          />
                        </el-input>
                      )
                    }
                    default: {
                      return (<div style="display: none">隐藏项</div>)
                    }
                  }
                })()
              }
            </el-form-item>
          )
        })}
      </el-form>
    );
  }
}

interface NPublic {
  readonly label: string;
  readonly default?: any;
  readonly placeholder?: string;
  readonly disabled?: boolean
}
interface NInput extends NPublic {
  readonly type: 'input';
  readonly key: string;
}
interface NNumber extends NPublic {
  readonly type: 'number';
  readonly key: string;
}
interface NBetween extends NPublic {
  readonly type: 'between';
  readonly keys: [string, string];
}
interface NSelect extends NPublic {
  readonly type: 'select';
  readonly key: string;
  readonly url?: string;
  readonly multiple?: boolean;
  options?: any[]
  readonly labelKey?: string;
  readonly valueKey?: string;
}
interface NDatepicker extends NPublic {
  readonly type: 'datepicker';
  readonly key: string;
  format?: 'yyyy-MM-dd' | 'yyyy-MM-dd hh:mm:ss',
}
interface NRangepicker extends NPublic {
  readonly type: 'rangepicker';
  readonly key: string;
  format?: 'yyyy-MM-dd' | 'yyyy-MM-dd hh:mm:ss',
}
