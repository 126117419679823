import { Component, Prop, Vue } from 'vue-property-decorator';
import { pca } from 'area-data';
import './query.scss';

@Component({
  name: 'cus-query',
})
export default class CusQuery extends Vue {

  @Prop() nodes!: any[];

  @Prop({ default: 'simple' }) mode!: 'default' | 'simple';

  private formGroup: any = {};

  constructor(props) {
    super(props);
    this.nodes.map((node, idx) => {
      if (node.keys) {
        this.formGroup[node.keys[0]] = null; 
        this.formGroup[node.keys[1]] = null; 
      } else {
        this.formGroup[node.key] = node.default;
      }
      if (node.region && node.region === 'province') {
        node.options = Object.entries(pca[86]).map(i => { return { id: i[0], name: i[1] } });
      }
      if (node.url) {
        this.axios.post(node.url).then((res: any) => {
          node.options = [...res.json, ...(node.options || [])];
          this.$set(this.nodes, idx, node)
        });
      }
    })
  }

  private submit() {
    let data = { ...this.formGroup };
    Object.keys(data).map(key => (data[key] === undefined || data[key] === null || data[key] === '') && delete data[key]);
    this.$emit('submit', data);
  }
  private reset() {
    Object.keys(this.formGroup).map(key => this.formGroup[key] = undefined);
  }

  /* 联动触发 */
  private linkage(v, node) {
    if (node.region && node.region === 'province') {
      this.nodes.map(n => {
        if (n.key === node.child) {
          n.options = v ? Object.entries(pca[v]).map(i => { return { id: i[0], name: i[1] } }) : [];
          this.formGroup[n.key] = null;
        }
        return n;
      })
    }
  }

  protected render() {
    return (
      <div class="cus__query__container">
        <input type="text" style="display:none" />

          {this.nodes.map((node: any) => {
            return (
              <div class="query__cell" data-before={ node.label }>
                {
                  (() => {
                    switch (node.type) {
                      case 'input': {
                        let n = node as NInput;
                        return (
                          <el-input
                            clearable
                            value={this.formGroup[n.key]}
                            onInput={(v: string) => { this.formGroup[n.key] = v }}
                            placeholder={n.placeholder || `请输入${n.label}`}
                            nativeOnKeydown={(e: any) => { e.keyCode === 13 && this.submit() }}
                          />
                        )
                      }
                      case 'number': {
                        let n = (node as NNumber);
                        return (
                          <el-input-number
                            clearable
                            controls-position="right"
                            value={this.formGroup[n.key]}
                            onInput={(v: number) => { this.formGroup[n.key] = v }}
                            placeholder={n.placeholder || `请输入${n.label}`}
                            nativeOnKeydown={(e: any) => { e.keyCode === 13 && this.submit() }}
                          />
                        )
                      }
                      case 'select': {
                        let n = node as NSelect;
                        return (
                          <el-select 
                            clearable
                            filterable={n.filterable}
                            value={this.formGroup[n.key]}
                            onInput={(v, a) => {
                              this.formGroup[n.key] = v; 
                              this.mode === 'simple' && this.submit();
                              node.child && this.linkage(v, node)
                            }} 
                            placeholder={n.placeholder || `请选择${n.label}`}>
                            {
                              n.options?.map(option => (
                                <el-option key={option[n.valueKey || 'id']} label={option[n.labelKey || 'name']} value={option[n.valueKey || 'id']} />
                              ))
                            }
                          </el-select>
                        )
                      }
                      case 'datepicker': {
                        let n = node as NDatepicker;
                        return (
                          <el-date-picker
                            type="date"
                            value-format={node.format || 'yyyy-MM-dd'}
                            value={this.formGroup[n.key]}
                            onInput={(v) => { this.formGroup[n.key] = v; this.mode === 'simple' && this.submit(); }}
                            placeholder={n.placeholder || `请选择${n.label}`}
                          />
                        )
                      }
                      case 'rangepicker': {
                        let n = node as NRangepicker;
                        return (
                          <el-date-picker
                            type="daterange"
                            value-format={node.format || 'yyyy-MM-dd'}
                            range-separator="~"
                            value={this.formGroup[n.key]}
                            onInput={(v) => { this.formGroup[n.key] = v; this.mode === 'simple' && this.submit(); }}
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                          />
                        )
                      }
                      case 'between': {
                        let n = node as NBetween;
                        return (
                          <el-input readonly value="~" style="vertical-align: initial">
                            <el-input
                              clearable
                              slot="prepend"
                              style={{width: '100px'}}
                              value={this.formGroup[n.keys[0]]}
                              onInput={(v: string) => { this.formGroup[n.keys[0]] = v }}
                              placeholder="开始"
                              nativeOnKeydown={(e: any) => { e.keyCode === 13 && this.submit() }}
                            />
                            <el-input
                              clearable
                              slot="append"
                              style={{ width: '100px' }}
                              value={this.formGroup[n.keys[1]]}
                              onInput={(v: string) => { this.formGroup[n.keys[1]] = v }}
                              placeholder="结束"
                              nativeOnKeydown={(e: any) => { e.keyCode === 13 && this.submit() }}
                            />
                          </el-input>
                        )
                      }
                      default: {
                        return (<span>“{ node.type }” 类型未被支持</span>)
                      }
                    }  
                  })()
                }  
              </div>
            )
          })}
        
        {
          this.mode !== 'simple' && (
            <div class="query__cell is__buttons">
              <el-button type="primary" onClick={this.submit}>查询</el-button>
              <el-button onClick={this.reset}>重置</el-button>
            </div>
          )
        }
        <div class="query__slot__buttons">{this.$slots.buttons}</div>
      </div>
    );
  }
}

interface NPublic {
  readonly label: string;
  readonly default?: any;
  readonly placeholder?: string;
}
interface NInput extends NPublic { 
  readonly type: 'input';
  readonly key: string; 
}
interface NNumber extends NPublic {
  readonly type: 'number';
  readonly key: string; 
}
interface NBetween extends NPublic {
  readonly type: 'between';
  readonly keys: [string, string];
}
interface NSelect extends NPublic {
  readonly type: 'select';
  readonly key: string;
  readonly url?: string;
  readonly multiple?: boolean;
  options?: any[]
  readonly filterable?: boolean;
  readonly labelKey?: string;
  readonly valueKey?: string;
}
interface NDatepicker extends NPublic {
  readonly type: 'datepicker';
  readonly key: string;
  format?: 'yyyy-MM-dd' | 'yyyy-MM-dd hh:mm:ss',
}
interface NRangepicker extends NPublic {
  readonly type: 'rangepicker';
  readonly key: string;
  format?: 'yyyy-MM-dd' | 'yyyy-MM-dd hh:mm:ss',
}